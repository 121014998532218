.App {
  background: radial-gradient(at center left,#d62649,#ff962d);
}

html {
  overflow-y: scroll;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container-sm {
  max-width: 815px;
  width: 100%;
}

.container-md {
  max-width: 890px;
  width: 100%;
}

.container-lg {
  max-width: 1024px;
  width: 100%;
}
.panel-sm {
  max-width: 475px;
  width: 100%;
}
.max-w-sm {
  max-width: 300px;
}
.gradient {
  background: linear-gradient(0deg,#d62649,#ff962d)
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}

/*Tooltip from tippy-react fix border-radius*/
.tippy-popper[x-placement^=top] [x-circle].enter{
  border-radius: 0px;
}

.tippy-tooltip{
  border-radius: 0.5rem;
}
